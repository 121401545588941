import React from 'react'
import './Clients.css'
import aguaplanet from '../imgs/AguaLogo.png'
import cgm from '../imgs/cgmLogo.png'
import coddi from '../imgs/coddi-01.png'
import duoc from '../imgs/duocLogo.png'
import eco from '../imgs/eco_lgo.png'
import gsfd from '../imgs/GSFD.png'
import inacap from '../imgs/inaLogo.png'
import blanklogo from '../imgs/LOGOwhiteNew.png'
import tect from '../imgs/tecLogo.png'
import teobservo from '../imgs/teobservo-01.png'
import tribo from '../imgs/tribologicosLogo-01.png'

const Clients = () => {
  return (
    <div className="clients-container">
      <div className="clients-content">
        <h1>Nuestros Clientes</h1>
        <p>
          DGA Studios SpA, tu confiable estudio de animación en Santiago, Chile.
          Ofrecemos una amplia gama de servicios, incluyendo animación 2D,
          animación 3D y motion graphics.
        </p>
        <p>
          {' '}
          Con más de 6 años de experiencia en la industria, nos hemos ganado la
          reputación de proporcionar productos de alta calidad con puntualidad.
        </p>
      </div>
      <div className="clients-logos">
        <img src={aguaplanet} alt="Client Logo 1" />
        <img src={cgm} alt="Client Logo 1" />
        <img src={coddi} alt="Client Logo 2" />
        <img src={duoc} alt="Client Logo 3" />
        <img src={eco} alt="Client Logo 4" />
        <img src={gsfd} alt="Client Logo 5" />
        <img src={inacap} alt="Client Logo 6" />
        <img src={blanklogo} alt="Client Logo 7" />
        <img src={tect} alt="Client Logo 8" />
        <img src={teobservo} alt="Client Logo 9" />
        <img src={tribo} alt="Client Logo 10" />
      </div>
    </div>
  )
}

export default Clients
