import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './MainBanner.css'
import cgm from '../imgs/cgm.jpg'
import inacap from '../imgs/inacap.jpg'
import nolocoma from '../imgs/nolocoma.jpg'
import planta from '../imgs/planta.jpg'
import tecnagent from '../imgs/tecnagent.jpg'
import tecnagent2 from '../imgs/tecnagent2.jpg'
import tribo from '../imgs/tribo.jpg'
import logo from '../imgs/DGA LOGO 2022 Final-01.png'
const MainBanner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  }

  return (
    <div className="main-banner">
      <div className="pattern-overlay"></div>
      <Slider {...settings}>
        <div>
          <img src={cgm} alt="Carousel Image 1" className="banner-image" />
        </div>
        <div>
          <img src={inacap} alt="Carousel Image 2" className="banner-image" />
        </div>
        <div>
          <img src={nolocoma} alt="Carousel Image 3" className="banner-image" />
        </div>
        <div>
          <img src={planta} alt="Carousel Image 1" className="banner-image" />
        </div>
        <div>
          <img
            src={tecnagent}
            alt="Carousel Image 2"
            className="banner-image"
          />
        </div>
        <div>
          <img
            src={tecnagent2}
            alt="Carousel Image 3"
            className="banner-image"
          />
        </div>
        <div>
          <img src={tribo} alt="Carousel Image 3" className="banner-image" />
        </div>
      </Slider>
      <div className="banner-content">
        <img src={logo} alt="" />
        <h1>Explora nuestra Creatividad en Acción</h1>

        <a href="https://vimeo.com/user42090177">
          {' '}
          <button className="cta-button">Ver Portafolio en Vimeo</button>
        </a>
      </div>
    </div>
  )
}

export default MainBanner
