import React from "react";
import "./TextBanner.css";
function TextBanner() {
  return (
    <div className="text-banner">
      <h1>Acerca de DGA Studios</h1>
      <p>
        En DGA Studios SpA, entendemos la importancia de comunicar tu mensaje de
        manera eficaz.
      </p>
      <p>
        {" "}
        Nuestros videos explicativos, videos de inducción, videos promocionales
        y videos educativos están diseñados para captar la atención de tu
        audiencia y transmitir tu mensaje de manera clara y concisa.
      </p>
      <p>
        {" "}
        Además, ofrecemos música original y voz en off para dar a tus videos un
        toque profesional y único.
      </p>
      <p>
        No importa si necesitas un video para tu empresa, una animación
        personalizada o un completo servicio de animación, estamos aquí para
        ayudarte.
      </p>
      <p>
        Nuestro equipo de expertos se esfuerza por superar las expectativas de
        nuestros clientes, creando soluciones de animación que se destacan en el
        mercado.
      </p>
      <p>
        Con DGA Studios SpA, siempre puedes esperar un excelente servicio y
        resultados excepcionales.
      </p>
    </div>
  );
}

export default TextBanner;
