import React from "react";
import MainBanner from "./components/MainBanner";
import Clients from "./components/Clients";
import TextBanner from "./components/TextBanner";
import Questionnaire from "./components/Questionnaire";

function App() {
  return (
    <div className="App">
      <Questionnaire />
      <Clients />
      <TextBanner />

      <MainBanner />
    </div>
  );
}

export default App;
